
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { AiApi } from "@evercam/shared/api/aiApi"

export default {
  name: "DepthAnalysis",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    timestamp: {
      type: String,
      required: true,
    },
    cameraExid: {
      type: String,
      required: true,
    },
    sidebarHeight: {
      type: String,
      default: "auto",
    },
  },
  data() {
    return {
      isProcessing: true,
      playerImageDimension: { height: 0, width: 0 },
      transparency: 10,
      error: false,
      depthBase64Image: null,
    }
  },
  computed: {
    depthImgStyle() {
      return {
        opacity: 1 - this.transparency / 100,
      }
    },
  },
  watch: {
    isProcessing(val) {
      this.$emit("is-processing", val)
    },
    timestamp: {
      immediate: true,
      handler: "performAnalysis",
    },
  },
  methods: {
    onTransparencySliderEnd(value) {
      this.$emit("transparency-change", value)
    },
    async performAnalysis() {
      this.isProcessing = true
      this.error = false

      if (!this.cameraExid || !this.timestamp) {
        this.isProcessing = false

        return
      }

      try {
        const { response } = await AiApi.brainTool.getDepth(
          this.cameraExid,
          this.timestamp
        )
        this.depthBase64Image = `data:image/png;base64,${response}`
      } catch (error) {
        this.error = true
        this.$notifications.error({
          text: this.$t("content.fetch_resource_failed", {
            resource: "brainTool results",
          }),
          error,
          notifyParams: {
            ERROR: error,
            REQUEST_PAYLOAD: {
              cameraExid: this.cameraExid,
              timestamp: this.timestamp,
            },
            FEATURE: "DepthAnalysis",
          },
        })
      } finally {
        this.isProcessing = false
      }
    },
  },
}
