
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { AiApi } from "@evercam/shared/api/aiApi"

export default {
  name: "Segmentation",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    imageDimensions: {
      type: Object,
      default: () => ({
        height: 0,
        width: 0,
      }),
    },
    timestamp: {
      type: String,
      required: true,
    },
    cameraExid: {
      type: String,
      required: true,
    },
    imgElement: {
      type: HTMLImageElement,
      required: true,
    },
  },
  data() {
    return {
      isProcessing: true,
      segments: [],
      transparency: 50,
      error: null,
      highlightedIndex: null,
      isHovered: false,
    }
  },
  computed: {
    svgStyles() {
      return {
        opacity: 1 - this.transparency / 100,
      }
    },
  },
  watch: {
    isProcessing(val) {
      this.$emit("is-processing", val)
    },
    timestamp: {
      immediate: true,
      handler: "performSegmentation",
    },
  },
  methods: {
    onTransparencySliderEnd(value) {
      this.$emit("transparency-change", value)
    },
    async performSegmentation() {
      this.error = null
      this.isProcessing = true
      this.segments = []
      try {
        const { response } = await AiApi.brainTool.getSegments({
          cameraex: this.cameraExid,
          timestamp: this.timestamp,
        })
        if (response === "segmentation-error") {
          this.error = response

          return
        }
        this.segments = response
          .filter(
            (segment) =>
              Array.isArray(segment?.mask) &&
              segment?.mask.every(
                (point) => Array.isArray(point) && point.length === 2
              )
          )
          .map((segment) => {
            return {
              label: segment.label,
              polygon: Object.values(segment.mask).map(([x, y]) => ({ x, y })),
            }
          })
      } catch (e) {
        console.error(e)
      } finally {
        this.isProcessing = false
      }
    },
    formatPoints(points) {
      return points
        .map(
          (p) =>
            `${p.x * this.imageDimensions.width},${
              p.y * this.imageDimensions.height
            }`
        )
        .join(" ")
    },
    stringToColor(s) {
      let hash = 0
      let str = `${s}${s}${s}`
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }

      let color = "#"
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        color += ("00" + value.toString(16)).substr(-2)
      }

      return color
    },
    cutImage(polygon) {
      let minX = Infinity
      let minY = Infinity
      let maxX = -Infinity
      let maxY = -Infinity

      for (const point of polygon) {
        minX = Math.min(minX, point.x * this.imageDimensions.width)
        minY = Math.min(minY, point.y * this.imageDimensions.height)
        maxX = Math.max(maxX, point.x * this.imageDimensions.width)
        maxY = Math.max(maxY, point.y * this.imageDimensions.height)
      }

      const width = maxX - minX
      const height = maxY - minY

      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")
      canvas.width = width
      canvas.height = height

      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.beginPath()
      ctx.moveTo(
        polygon[0].x * this.imageDimensions.width - minX,
        polygon[0].y * this.imageDimensions.height - minY
      )
      for (let i = 1; i < polygon.length; i++) {
        ctx.lineTo(
          polygon[i].x * this.imageDimensions.width - minX,
          polygon[i].y * this.imageDimensions.height - minY
        )
      }
      ctx.closePath()
      ctx.clip()
      ctx.drawImage(
        this.imgElement,
        -minX,
        -minY,
        this.imageDimensions.width,
        this.imageDimensions.height
      )

      return canvas.toDataURL()
    },
    calculateWidth(polygon) {
      let minX = Infinity
      let maxX = -Infinity

      for (const point of polygon) {
        minX = Math.min(minX, point.x * this.imageDimensions.width)
        maxX = Math.max(maxX, point.x * this.imageDimensions.width)
      }

      let width = maxX - minX
      if (width > this.parentWidth) {
        width = this.parentWidth
      }

      return width
    },
    calculateHeight(polygon) {
      let minY = Infinity
      let maxY = -Infinity

      for (const point of polygon) {
        minY = Math.min(minY, point.y * this.imageDimensions.height)
        maxY = Math.max(maxY, point.y * this.imageDimensions.height)
      }

      let height = maxY - minY

      if (height > this.parentHeight) {
        height = this.parentHeight
      }

      return height
    },
    handleImageHover(segment, isHovered) {
      segment.isHovered = isHovered
      if (isHovered) {
        this.highlightedIndex = segment.label
      } else {
        this.highlightedIndex = null
        this.segments.forEach((seg) => {
          seg.isHovered = false
        })
      }
    },
  },
}
