
import Vue, { type PropType } from "vue"
import type { DetectionLabel } from "@evercam/shared/types"
import { stringToColor, toTitleCase } from "@evercam/shared/utils"

export default Vue.extend({
  name: "BoundingBox",
  props: {
    label: {
      type: String as PropType<DetectionLabel>,
      required: true,
    },
    xMin: {
      type: Number,
      required: true,
    },
    yMin: {
      type: Number,
      required: true,
    },
    xMax: {
      type: Number,
      required: true,
    },
    yMax: {
      type: Number,
      required: true,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    boundingBoxStyle(): Record<string, string> {
      const coords = {
        left: this.xMin,
        top: this.yMin,
        height: this.yMax - this.yMin,
        width: this.xMax - this.xMin,
      }

      return {
        "--box-color": stringToColor(this.label),
        top: `${coords.top * 100}%`,
        left: `${coords.left * 100}%`,
        height: `${coords.height * 100}%`,
        width: `${coords.width * 100}%`,
        transition: "width 0.2s, height 0.2s",
      }
    },
  },
  beforeDestroy() {
    this.$emit("destroy-object-path")
  },
  methods: {
    getFormattedLabel(label: string): string {
      return toTitleCase(label)
    },
  },
})
